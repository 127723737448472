import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Radio,
  Input,
  Segment,
  Tab,
  Menu,
  List,
  Button,
  Dropdown,
  Popup
} from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import {
  addUniqueFieldConditionAll,
  updateUniqueFieldConditionAll,
  removeUniqueFieldConditionAll,
  addUniqueFieldConditionAny,
  removeUniqueFieldConditionAny,
  addSchemaFieldConditionAll,
  updateSchemaFieldConditionAll,
  removeSchemaFieldConditionAll,
  addSchemaFieldConditionAny,
  removeSchemaFieldConditionAny,
  editSchemaFieldContexts,
  editUniqueFieldContexts
} from '../../../../state/actions';

@connect((state) => {
  return {
    schema: state.editSchemaSchema
  };
})
@withRouter
class ContextEditor extends Component {
  getFieldInput = (value, action) => {
    const { match, schema } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    if (isSchemaEditor) {
      const fieldOptions = schema.fields.map((x) => ({
        key: x.id,
        text: x.label,
        value: x.id
      }));
      return (
        <Form.Field>
          <Dropdown
            label="Field"
            value={value}
            onChange={action}
            selection
            options={fieldOptions}
          />
        </Form.Field>
      );
    }
    return (
      <Form.Field>
        <Input label="Field" value={value} onChange={action} />
      </Form.Field>
    );
  };
  getValueInput = (target, value, action, operator) => {
    const { match, schema } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    if (isSchemaEditor) {
      const targetField = schema.fields.find((x) => x.id === target);

      if (targetField && targetField.options && targetField.options.length > 0) {
        return (
          <Form.Field>
            <Dropdown
              label="Field"
              value={value}
              onChange={action}
              selection
              multiple
              disabled={operator === 'invalid'}
              options={targetField.options}
            />
          </Form.Field>
        );
      }
    }

    return (
      <Form.Field>
        <Input
          label="Value"
          value={value}
          onChange={action}
          disabled={operator === 'invalid'} // Disable if "Invalid"
        />
      </Form.Field>
    );
  };

  renderConditionals = (checked, valueToSet, context) => {
    const { conditions } = context;
    const cons = conditions.find((c) => c.as === valueToSet);

    const hasConditions = cons && cons.any && cons.any.length;
    const otherConditions = conditions.filter((c) => c.as !== valueToSet);
    const hasOtherConditions = otherConditions.some((c) => c.any && c.any.length);
    const showWhenOtherConditionsNotMet = checked && hasOtherConditions;
    const showAlways = checked && !hasOtherConditions;

    let content;

    let getElemProps;

    if (cons) {
      content = cons.any.map((any, i) => {
        const ands = any.all.map((all, j) => {
          return (
            <List.Item key={`${cons.as}-any-${i}-all-${j}`}>
              <Segment>
                <Button negative onClick={this.removeConditionAll(context, valueToSet, i, all)}>
                  <Icon name="minus" />
                  Remove AND condition.
                </Button>
                {this.getFieldInput(
                  all.field,
                  this.updateConditionAll(context, valueToSet, i, all, 'field')
                )}
                <Form.Field>
                  <Dropdown
                    label="Operator"
                    value={all.operator || 'eq'}
                    selection
                    options={[
                      { key: 'in', value: 'in', text: 'In' },
                      {
                        key: 'invalid',
                        value: 'invalid',
                        text: (
                          <span>
                            Invalid{' '}
                            <Popup
                              trigger={<Icon name="info circle" />}
                              content="Invalid is treated as: does not exist, is null, or is empty."
                              position="top center"
                            />
                          </span>
                        )
                      }
                    ]}
                    onChange={this.updateConditionAll(context, valueToSet, i, all, 'operator')}
                  />
                </Form.Field>

                {
                  (getElemProps = this.getValueInput(
                    all.field,
                    all.value,
                    this.updateConditionAll(context, valueToSet, i, all, 'value'),
                    all.operator // Pass the operator for disabling
                  ))
                }

                {getElemProps.props.children.props?.options
                  ? getElemProps.props.children.props?.value.length
                    ? 'Multiple selections treated as ORs'
                    : ''
                  : getElemProps.props.children.props?.value.length
                  ? 'Values with || will be split into ORs'
                  : ''}
              </Segment>
              {j === any.all.length - 1 ? null : <Divider horizontal>AND</Divider>}
            </List.Item>
          );
        });

        return (
          <List.Item key={`${cons.as}-any-${i}`}>
            <Segment>
              <Button negative onClick={this.removeConditionAny(context, valueToSet, i)}>
                <Icon name="minus" />
                Remove OR condition.
              </Button>

              {ands}
              <Button onClick={this.addConditionalAll(context, valueToSet, i)}>
                <Icon name="plus" />
                Add AND Condition
              </Button>
            </Segment>
            {i === cons.any.length - 1 ? null : <Divider horizontal>OR</Divider>}
          </List.Item>
        );
      });
    }

    if (showWhenOtherConditionsNotMet) {
      content = <Segment>When other conditions are not met.</Segment>;
    }
    if (showAlways) {
      content = <Segment>Always</Segment>;
    }
    if (!checked && !hasConditions) {
      content = <Segment>Never</Segment>;
    }

    return (
      <List>
        {content}
        {showAlways || showWhenOtherConditionsNotMet ? null : (
          <Button onClick={this.addConditionalAny(context, valueToSet)}>
            <Icon name="plus" />
            Add Or Condition
          </Button>
        )}
      </List>
    );
  };
  addConditionalAll = (context, as, index) => {
    return (e) => {
      const { dispatch, match, field } = this.props;
      const isSchemaEditor = match.path.startsWith('/schema-editor');
      const action = isSchemaEditor ? addSchemaFieldConditionAll : addUniqueFieldConditionAll;

      dispatch(
        action({
          field,
          context,
          as,
          index
        })
      );
    };
  };

  updateConditionAll = (context, as, index, item, prop) => {
    return (e, { value }) => {
      const { dispatch, match, field } = this.props;

      const isSchemaEditor = match.path.startsWith('/schema-editor');
      const action = isSchemaEditor ? updateSchemaFieldConditionAll : updateUniqueFieldConditionAll;
      // Reset Value field if operator changed
      if (prop === 'operator') {
        if (value === 'invalid') {
          item.value = []; // Reset if invalid is selected
        } else if (item.operator === 'invalid') {
          // Clear state of value if changed to invalid
          item.value = [];
        }
      }
      if (prop === 'value' && typeof value === 'string') {
        value = [value];
      }
      if (prop === 'field' && value !== item.field) {
        item.value = [];
      }

      dispatch(
        action({
          field,
          context,
          as,
          index,
          item,
          prop,
          value
        })
      );
    };
  };

  removeConditionAll = (context, as, index, item) => {
    return (e) => {
      const { dispatch, match, field } = this.props;

      const isSchemaEditor = match.path.startsWith('/schema-editor');
      const action = isSchemaEditor ? removeSchemaFieldConditionAll : removeUniqueFieldConditionAll;

      dispatch(
        action({
          field,
          context,
          as,
          index,
          item
        })
      );
    };
  };

  addConditionalAny = (context, as) => {
    return (e) => {
      const { dispatch, match, field } = this.props;
      const isSchemaEditor = match.path.startsWith('/schema-editor');
      const action = isSchemaEditor ? addSchemaFieldConditionAny : addUniqueFieldConditionAny;

      dispatch(
        action({
          field,
          context,
          as
        })
      );
    };
  };
  removeConditionAny = (context, as, index) => {
    return (e) => {
      const { dispatch, match, field } = this.props;
      const isSchemaEditor = match.path.startsWith('/schema-editor');
      const action = isSchemaEditor ? removeSchemaFieldConditionAny : removeUniqueFieldConditionAny;

      dispatch(
        action({
          field,
          context,
          as,
          index
        })
      );
    };
  };

  makeContextTab = ({ label, groupName, onChange, value, valueToSet, context }) => {
    const checked = value === valueToSet;

    return (
      <Form.Field>
        <Radio
          toggle
          label={label}
          name={groupName}
          onChange={onChange}
          checked={checked}
          value={valueToSet}
        />
        {this.renderConditionals(checked, valueToSet, context)}
      </Form.Field>
    );
  };
  makeContextForm = ({ groupName, label, name, icon, info, onChange, value, context }) => {
    return {
      menuItem: (
        <Menu.Item key={label}>
          <Header as="h3">
            <Icon name={icon} />
            <Header.Content>
              {label}
              <Header.Subheader>{info}</Header.Subheader>
            </Header.Content>
          </Header>
        </Menu.Item>
      ),
      render: () => {
        return (
          <Form>
            <Divider />
            <br />
            <Grid columns="equal">
              <Grid.Column>
                {this.makeContextTab({
                  label: 'Render As Input',
                  groupName,
                  onChange,
                  value,
                  valueToSet: 'input',
                  context
                })}
              </Grid.Column>
              <Grid.Column>
                {this.makeContextTab({
                  label: 'Read Only Field',
                  groupName,
                  onChange,
                  value,
                  valueToSet: 'read-only',
                  context
                })}
              </Grid.Column>
              <Grid.Column>
                {this.makeContextTab({
                  label: 'Do Not Render',
                  groupName,
                  onChange,
                  value,
                  valueToSet: 'hide',
                  context
                })}
              </Grid.Column>
            </Grid>
          </Form>
        );
      }
    };
  };

  changeHandler = (fieldContext) => {
    const { dispatch, field, match } = this.props;
    return (e, { value }) => {
      const isSchemaEditor = match.path.startsWith('/schema-editor');
      const action = isSchemaEditor ? editSchemaFieldContexts : editUniqueFieldContexts;

      dispatch(
        action({
          value,
          field,
          context: fieldContext
        })
      );
    };
  };

  renderCompanyOrderingContext = (context) => {
    return this.makeContextForm({
      label: 'Company Ordering',
      groupName: 'companyOrdering',
      icon: 'building outline',
      info: 'Company Ordering Application',
      value: context.render,
      context,
      onChange: this.changeHandler(context)
    });
  };

  mobileAppContext = (context) => {
    return this.makeContextForm({
      label: 'Looker App',
      groupName: 'mobile-app',
      icon: 'mobile',
      info: `The Looker's Mobile App`,
      value: context.render,
      context,
      onChange: this.changeHandler(context)
    });
  };

  CSRContext = (context) => {
    return this.makeContextForm({
      label: 'WGL CSR',
      groupName: 'csr',
      icon: 'commenting',
      info: 'We Go Look Customer Service',
      value: context.render,
      context,
      onChange: this.changeHandler(context)
    });
  };

  generatePreview = ({ readOnly, hide }) => {
    if (hide) {
      return (
        <Segment>
          <div />
        </Segment>
      );
    }

    return (
      <Segment>
        <ValidatedForm readOnly={readOnly}>
          <Form.Input
            fluid
            label="Example"
            placeholder="Enter a new password"
            name="example"
            value={'TODO: attach this to controls'}
          />
        </ValidatedForm>
      </Segment>
    );
  };

  inputByEnv = (env) => {
    var inputs = {
      'wgl-csr': this.CSRContext,
      'client-ordering': this.renderCompanyOrderingContext,
      'looker-app': this.mobileAppContext
    };

    return inputs[env] || null;
  };

  contextToContent = (context, index) => {
    const contextInput = this.inputByEnv(context.environment);
    if (!contextInput) {
      console.warn(`Looks like you've indicated a context environment we didn't expect`);
      return null;
    }
    return contextInput(context);
  };

  render() {
    const { field } = this.props;
    const { contexts } = field;
    const content = _.map(contexts, this.contextToContent);

    return <Tab panes={content} />;
  }
}
export default ContextEditor;
