import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import createReducer from './create-reducer';

const initial = {
  progress: 0,
  targetId: null,
  copies: []
};

const reducers = {
  SET_COPY_MACHINE_TARGET_ID: (state, value) => {
    return {
      ...state,
      ...{
        targetId: value
      }
    };
  },
  ADD_COPY_MACHINE_COPY: (state, value) => {
    const { sourceDocument, copy, index } = value;

    let _copy = _.merge({}, sourceDocument, copy);

    _copy = _.omit(_copy, ['author', 'authorId', 'createdAt', '_id', 'updatedAt']);

    _copy.status = 'draft';

    _copy.fields = _.map(_copy.fields, (field) => {
      let _field = _.omit(field, ['tooltip']);

      _copy.groups.forEach((fg) => {
        let fieldIndex = fg.fields.findIndex((x) => x === field.id);
        if (fieldIndex >= 0) {
          fg.fields[fieldIndex] = field.id;
        }
      });

      return _field;
    });

    _copy.lineItems = _.map(_copy.lineItems, (lineItem) => {
      let _lineItem = _.omit(lineItem, ['lineItem']);
      return _lineItem;
    });

    _copy.companies = _.map(_copy.companies, (company) => {
      let _company = _.omit(company, ['company']);
      _company.lineItems = _.map(_company.lineItems, (lineItem) => {
        let _lineItem = _.omit(lineItem, ['lineItem']);
        return _lineItem;
      });
      return _company;
    });

    _copy.tempId = uuid();

    const _index = index + 1;
    return {
      ...state,
      ...{
        copies: [
          ...state.copies.slice(0, _index),
          _copy,
          ...state.copies.slice(_index, state.copies.length)
        ]
      }
    };
  },
  REMOVE_COPY_MACHINE_COPY: (state, value) => {
    return {
      ...state,
      ...{
        copies: state.copies.filter((c) => {
          return c.tempId !== value.tempId;
        })
      }
    };
  },
  UPDATE_COPY_MACHINE_COPIES: (state, value) => {
    return {
      ...state,
      ...{
        copies: [...value]
      }
    };
  },
  CLEAR_COPY_MACHINE_COPIES: (state, value) => {
    return {
      ...state,
      ...{
        copies: []
      }
    };
  }
};

export default createReducer(initial, reducers);
