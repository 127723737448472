import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';

import getMapHistory from '../../../graphql/queries/getMapHistory.graphql';
import { SearchActionButtonSection } from '../../search/actionSection';
import SearchTable from '../../search/search-table';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

function actionSection(actions) {
  return [
    {
      icon: 'share alternate',
      label: 'View Diff',
      action: actions.diff
    },
    {
      icon: 'reply',
      label: 'View Version',
      action: actions.version
    }
  ];
}

@connect(stateToComponent)
@graphql(getMapHistory, {
  name: 'mapHistory',
  options: (props) => {
    const { id } = props.match.params;

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id
      }
    };
  }
})
class MapView extends Component {
  historyTableModel = [
    {
      label: 'Version',
      property: 'version',
      type: 'string'
    },
    {
      label: 'User',
      property: 'user.firstName',
      type: 'string'
    },
    {
      label: 'Date',
      property: 'updatedAt',
      type: 'date'
    },
    {
      label: 'Action',
      property: 'method',
      type: 'string'
    },
    {
      label: 'Actions',
      type: 'component',
      component: SearchActionButtonSection,
      model: actionSection
    }
  ];

  loadMapDiff = (map) => {
    const { history } = this.props;
    const { id } = this.props.match.params;
    history.push(`/maps/history/${id}/diff/${map.version}`);
  };

  loadMapVersion = (map) => {
    const { history } = this.props;
    const { id } = this.props.match.params;
    history.push(`/maps/history/${id}/version/${map.version}`);
  };

  render() {
    const { mapHistory } = this.props;
    if (mapHistory.loading) {
      return <div />;
    }
    const diffs = mapHistory.mapVersionById.diffs;

    return (
      <SearchTable
        who={null}
        items={diffs}
        model={this.historyTableModel}
        actions={{
          diff: this.loadMapDiff,
          version: this.loadMapVersion
        }}
        area="history"
      />
    );
  }
}

export default MapView;
